import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export function setAccessToken(state, token) {
  cookies.set("accessToken", token, null, "/");
  state.accessToken = token;
}

export function setRefreshToken(state, token) {
  cookies.set("refreshToken", token, null, "/");
  state.refreshToken = token;
}

export function setUserIsLogged(state, value) {
  cookies.set("userIsLogged", value, null, "/");
  state.isLogged = value;
}

export function setIsFreeTrialExpired(state, value) {
  cookies.set("isFreeTrialExpired", value, null, "/");
  state.isFreeTrialExpired = value;
}

export function setUserRole(state, role) {
  cookies.set("userRole", role, null, "/");
  state.userData["userRole"] = role;
}

export function setTOTPLink(state, totp) {
  state.MFA["totp"].link = totp;
}

export function setTOTPRecovery(state, recovery) {
  state.mfa["totp"].recovery = recovery;
}

export function setUserData(state, data) {
  cookies.set("userId", data["user_id"], null, "/");
  cookies.set("userName", data["user_name"], null, "/");
  cookies.set("firstName", data["first_name"], null, "/");
  cookies.set("lastName", data["last_name"], null, "/");
  cookies.set("userRole", data["user_role"], null, "/");
  cookies.set("phoneNumber", data["phone_number"], null, "/");
  cookies.set("guardianOptIn", data["guardian_opt_in_status"], null, "/");
  cookies.set("mfaActivated", data["MFA_activated"], null, "/");
  cookies.set("last_login", data["last_login"], null, "/");
  cookies.set("dependents", data["dependents"], null, "/");
  cookies.set("type", data["type"], null, "/");
  cookies.set("location", data["location"], null, "/");
  cookies.set("email", data["email"], null, "/");
  cookies.set("is_active", data["is_active"], null, "/");
  sessionStorage.setItem("nChildren", data["dependents"]);
  state.userData["id"] = data["user_id"];
  state.userData["name"] = data["user_name"];
  state.userData["firstName"] = data["first_name"];
  state.userData["lastName"] = data["last_name"];
  state.userData["role"] = data["user_role"];
  state.userData["phoneNumber"] = data["phone_number"];
  state.userData["guardianOptIn"] = data["guardian_opt_in_status"];
  state.userData["mfaActivated"] = data["MFA_activated"] === "true";
  state.userData["last_login"] = data["last_login"];
  state.userData["dependents"] = data["dependents"];
  state.userData["type"] = data["type"];
  state.userData["location"] = data["location"];
  state.userData["email"] = data["email"];
  state.userData["is_active"] = data["is_active"];
  state.userData["nChildren"] = data["dependents"];
}

export function setUserName(state, name) {
  cookies.set("userName", name);
  state.userData["name"] = name;
}

export function setFullName(state, { firstName, lastName }) {
  cookies.set("firstName", firstName, null, "/");
  cookies.set("lastName", lastName, null, "/");

  state.userData["firstName"] = firstName;
  state.userData["lastName"] = lastName;
}

export function setEmail(state, email) {
  cookies.set("email", email, null, "/");

  state.userData["email"] = email;
}

export function setPhoneNumber(state, phoneNumber) {
  cookies.set("phoneNumber", phoneNumber, null, "/");

  state.userData["phoneNumber"] = phoneNumber;
}

export function setGuardianOptIn(state, guardianOptIn) {
  cookies.set("guardianOptIn", guardianOptIn, null, "/");

  state.userData["guardianOptIn"] = guardianOptIn;
}

export function setUserId(state, id) {
  cookies.set("userId", id);
  state.userData["userId"] = id;
}

export function setUserDependents(state, dependents) {
  state.dependents = dependents;
}

export function logout(state) {
  cookies.keys().forEach((cookie) => {
    cookies.remove(cookie);
  });
  state.accessToken = "";
  state.refreshToken = "";
  state.userData = {};
  state.isLogged = false;
  state.timeZone = {};
  state.gender = "";
  state.notifications = [];
  state.error = false;
  state.errorMessage = "";
  state.mfa = {
    totp: {
      link: "",
      recovery: [],
    },
  };
}

export function registerError(state, payload) {
  state.error = true;
  state.errorMessage = payload;
}

export function cleanErrors(state) {
  state.error = false;
  state.errorMessage = "";
}
