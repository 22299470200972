export const STRIPE_URLS = {
  createClient: "/stripe/create_customer/",
  createSeats: "/stripe/buy_sponsors/",
  createSubscription: "/stripe/create_subscription/",
  createB2CUser: "/stripe/b2c_user/",
  createPay: "/stripe/pay/",
  getStripeDiscountCode: "/stripe/stripe_discount_code/",
  getProduct: (code, location) =>
    `/stripe/get_product/?code=${code}&location=${location}`,
  getSubscriptionsSummary: "/stripe-overview/subscriptions_summary/",
  getMonthlyRevenueSummary: "/stripe-overview/monthly_revenue_summary/",
  getUserSubscriptionsSummary: "/stripe-overview/user_subscriptions_summary/",
  getOrganizationsSummary: "/stripe-overview/organizations_summary/",
  getOrganizationUsersSummary: "/stripe-overview/organization_users_summary/",
  cancelFullSubscription: "/stripe/cancel_full_subscription/",
  cancelPartialSubscription: "/stripe/cancel_partial_subscription/",
  getStripeSubscriptionInfo: "/stripe/stripe_subscription_info/",
  subscriptionNextInvoiceTotalBeforeQuantityChange: "/stripe/subscription_next_invoice_total_before_quantity_change/",
  subscriptionNextInvoiceTotal: "/stripe/subscription_next_invoice_total/",
  reactiveSubscription: "/stripe/reactive_subscription/",
  reactivePartialSubscription: "/stripe/reactive_partial_subscription/",
  confirmSubscriptionReactivation: "/stripe/confirm_subscription_reactivation/",
  attachPaymentMethod: "/stripe/attach_payment_method/",
  getPlanPrices: "/stripe/plan_prices/",
  addNewPLayerToStripeSubscription: "/stripe/add_new_player_to_subscription/",
  getTierPrices: "/stripe/tier_prices/",
  removeLocationUser: "/stripe/remove_location_user/",
  cancelLocationSubscription: "/stripe/cancel_location_subscription/",
  getDefaultPaymentMethod: "/stripe/default_payment_method/",
};

export const IN_APP_PURCHASE_URLS = {
  getAppStoreSubscriptionInfo: "/app_store/app_store_subscription_info/",
  getGooglePlayStoreSubscriptionInfo: "/google_play_store/google_play_store_subscription_info/",
  inAppPurchaseDeactivateUsers: "/in_app_purchases/deactivate_users/",
  inAppPurchasedependentsToAddOrReactivate : "/in_app_purchases/dependents_to_add_or_reactivate/",
  inAppPurchaseReactivateUsers: "/in_app_purchases/reactivate_users/",
}

export const AUTH_URLS = {
  verifyEmail: "/verify-email/",
  changePassword: "/change_password/",
  resetPassword: "/reset_password/",
  resetPasswordChange: "/reset_password/change/",
  resetPasswordValidate: "/reset_password/validate_token/",
};

export const LOCATION_URLS = {
  baseUrl: "/location/",
  editLocation: (id) => `/location/${id}/`,
  createLocationCode: "/location/location_code/",
  registrationEmail: "/registration-email/",
  getLocations: "/location/",
  getLocationPrices: "/location/prices/",
  validateFacilityCode: "/location/facility_code/",
  getNumberSeats: "/seats/",
  subtractSeat: "/seats/subtract_seat/",
  plustSeats: "/seats/plus_seats/",
  getLocationUsers: (page) => `/location/location_users/?page=${page}`,
  getUsers: (page) => `/location/location_users/?page=${page}`,
  getInvitations: (page) => `/invitations/?page=${page}`,
  getTotalUsers: (location) =>
    `/analytics/locations/total_users/?location=${location}`,

  getTotalSeats: (location) => `/seats/total_seats/?location=${location}`,
  getTotalTrainigSessions: (location, days) =>
    `/analytics/locations/total_sessions/?location=${location}&days=${days}`,
  getTotalUsersPerSessions: (location, days) =>
    `/analytics/locations/total_users_session/?location=${location}&days=${days}`,
  getTotalDelegates: (location) =>
    `/location/total_delegates/?location=${location}`,
  getTotalLocationAdmins: "/location/total_location_admins/",
  invitations: "/invitations/",
  getFreeTrial: "/location/free_trial/",
  getPaymentDetails: (page) => `/user-payment-details/?page=${page}`,
  userAssessmentScore: "/user-assessment/user_scores/",
};

export const OCULUS_URLS = {
  oculusRelease: "/oculus-release/",
  oculusManual: "/oculus-manual/",
};

export const ANALYTICS_URLS = {
  getTotalSessions: `/analytics/users/complete_sessionss/`,
  getTotalTrials: `/analytics/users/trials/`,
  getTotalTrialsMinutes: `/analytics/users/total_trial_minutes/`,
  getTotalModes: `/analytics/users/total_modes/`,
  getUserData: (page) =>
    `/analytics/locations/location_user_data/?page=${page}`,
  getUserDataReport: `/analytics/locations/location_user_data_report/`,
  getUsersOverview: `/analytics/users-overview/`,
};

export const SESSION_URLS = {
  getTotalSessions: (location) => `/session/?location=${location}`,
};

export const TRIAL_URLS = {
  getTrials: "/trial/",
  averageTime: (location) => `/trial/average_time/?location=${location}`,
};

export const VERSIONS_URLS = {
  getVersions: "app-info/versions/",
};

export const USER_URLS = {
  login: "/login/",
  refreshToken: "/token/refresh/",
  register: "/register/",
  createTOTPDevice: "/totp_create/",
  verifyTOTPDevice: "/totp_verify/",
  recoveryCode: "/static_create/",
  verifyRecoveryCode: "/static_verify/",
  sendRegistrationCode: (id) => `/user/${id}/`,
  createDependent: "/dependent/",
  totalUsers: "/user/total/",
  userGameStats: (id) => `/user/${id}/game_started/`,
  userTrialAverage: (id) => `/user/${id}/average_time/`,
  userOculusLogins: (id) => `/user/${id}/oculus_logins/`,
  getDependents: (id) => `/user/${id}/get_number_dependents/`,
  getGTMEvents: (id) => `/user/${id}/gtm_events/`,
  updateGTMEvents: (id) => `/user/${id}/update_gtm_events/`,
  getProfiles: "/dependent/",
  activateDeactivateProfile: (id) =>
    `/dependent/${id}/activate_deactivate_profile/`,
  editPlayer: (id) => `/dependent/${id}/`,
  editUser: (id) => `/user/${id}/ `,
  getAllDependents: (id) => `/dependent/${id}/profiles/`,
  getUsers: "/user/",
  getMessages: "/messages/?platform=Web",
  getAssessmentReports: (id) => `/user-assessment/${id}/`,
  getAssesmentReportByFileName: (id, fileName) =>
    `/user-assessment/${id}/by_name/?filename=${fileName}`,
  getSubscriptionProvider: "/user/subscription_provider/",
  getFreeTrialData: "/user/free_trial_data/",
  createFreeTrialUserPaymentInstance: "/user/create_free_trial_user_payment_instance/",
};

export const TASK_URLS = {
  baseUrl: "/task/",
  editTask: (id) => `/task/${id}/`,
  groupBaseUrl: "/group/",
  taskGroupBaseUrl: "/task_group/",
};

export const SURVEY_URLS = {
  getQuestions: (id) => `/user_survey/${id}/ `,
  setUserAnswers: "/user_answer/",
};
