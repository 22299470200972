import axios from "axios";
import store from "@/store";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const originalResponse = error.response;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      originalResponse.data.messages[0].message ===
        "Token is invalid or expired"
    ) {
      originalRequest._retry = true;
      const refreshToken = store.getters["user/getRefreshToken"];
      try {
        // Solicitar un nuevo access token utilizando el refresh token
        const response = await store.dispatch(
          "user/refreshToken",
          refreshToken
        );
        originalRequest.headers.Authorization = `Bearer ${response.access}`;

        return axios(originalRequest);
      } catch (err) {
        store.commit("user/logout");
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  (config) => {
    let accessToken = store.getters["user/getAccessToken"];
    if (accessToken && !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
