import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const isLogged = () => {
  return store.getters["user/getUserIsLogged"];
};

const hasFreeTrialExpired = () => {
  return store.getters["user/getIsFreeTrialExpired"];
};

/*////////////////////////////////////////////////////////////
//  "[ROUTE]": ['routes', 'that', 'can', 'access', 'ROUTE'] //
////////////////////////////////////////////////////////////*/
const securedRoutes = {
  "/forgot_password": ["/login"],
  "/totplogin": ["/login", "/recovery"],
  "/recovery": ["/totplogin"],
  "/totpregister": ["/login"],
  // "/totpregister": ["/login", "/signup"],
};

const canAccess = (to, from) => {
  if (isLogged()) {
    return "/";
  }
  if (securedRoutes[to.path].includes(from.path)) {
    return true;
  }

  return "error404";
};

const routes = [
  {
    path: "/:catchAll(.*)",
    component: () => import("@/views/general/error404.vue"),
  },
  {
    path: "/",
    component: () => import("@/layouts/UserLayout.vue"),
    beforeEnter: (to, from, next) => {
      if (!isLogged()) {
        next(`/login?redirect=${to.fullPath}`);
      } else if (isLogged() && hasFreeTrialExpired() && to.name !== 'Free trial end') {
        next({ name: 'Free trial end' });
      } else {
        next();
      }
    },
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/welcome",
        name: "Welcome",
        component: () => import("@/views/Welcome.vue"),
      },
      {
        path: "manage/players",
        name: "Manage players",
        component: () =>
          import("@/views/manage/locations/CreateLocationUsers.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
        children: [
          {
            path: "edit-profile/:id",
            name: "Edit user profiles",
            component: () => import("@/views/profile/DeactivateProfile.vue"),
          },
          {
            path: "free-trial/",
            name: "Free trial",
            component: () => import("@/views/manage/locations/FreeTrial.vue"),
          },
        ],
      },
      {
        path: "users",
        name: "Users",
        component: () => import("@/views/manage/users/list.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "user/:id",
        name: "User charts",
        component: () => import("@/views/manage/users/charts.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "sponsor-account",
        name: "Register sponsor account",
        component: () =>
          import(
            "@/views/manage/users/sponsorsAccounts/createSponsorAccount.vue"
          ),
      },
      {
        path: "manage/delegates",
        name: "Create location delegates",
        component: () =>
          import(
            "@/views/manage/users/locationUsers/registerLocationDelegate.vue"
          ),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "manage/sponsor-account",
        name: "Create sponsor account",
        component: () =>
          import(
            "@/views/manage/users/sponsorsAccounts/registerSponsorAccount.vue"
          ),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "manage/location-users",
        name: "Create location users",
        component: () =>
          import("@/views/manage/users/locationUsers/registerLocationUser.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "manage/location-admins",
        name: "Create location admins",
        component: () =>
          import(
            "@/views/manage/users/locationUsers/registerLocationAdmin.vue"
          ),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "manage/programs",
        name: "Manage Programs",
        component: () => import("@/views/manage/programs.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "manage/tasks",
        name: "Manage Tasks",
        component: () => import("@/views/manage/tasks.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "settings",
        name: "Settings",
        component: () => import("@/views/profile/ProfileSettings.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "manage/delegates",
        name: "Create location delegates",
        component: () =>
          import(
            "@/views/manage/users/locationUsers/registerLocationDelegate.vue"
          ),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "manage/sponsor-account",
        name: "Create sponsor account",
        component: () =>
          import(
            "@/views/manage/users/sponsorsAccounts/registerSponsorAccount.vue"
          ),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "manage/location-users",
        name: "Create location users",
        component: () =>
          import("@/views/manage/users/locationUsers/registerLocationUser.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "manage/location-admins",
        name: "Create location admins",
        component: () =>
          import(
            "@/views/manage/users/locationUsers/registerLocationAdmin.vue"
          ),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "manage/programs",
        name: "Manage Programs",
        component: () => import("@/views/manage/programs.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "manage/tasks",
        name: "Manage Tasks",
        component: () => import("@/views/manage/tasks.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "settings/profile",
        name: "Settings Profile",
        component: () => import("@/views/profile/ProfileSettings.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "account/user-payments",
        name: "User Payments",
        component: () => import("@/views/manage/users/UserPayments.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "assessment-tracking/by-user",
        name: "User Assessment Scores",
        component: () => import("@/views/manage/users/UserAssessmentScores.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "assessment-tracking/by-assessment",
        name: "Assessment overview",
        component: () => import("@/views/manage/users/AssessmentOverview.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "settings/change-password",
        name: "Change Password",
        component: () => import("@/views/profile/ProfileChangePassword.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "settings/players",
        name: "Players",
        component: () => import("@/views/players/Players.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "settings/managesubscription",
        name: "Manage Subscription",
        component: () =>
          import("@/views/manage/subscription/ManageSubscription.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "settings/contactsupport",
        name: "Contact Support",
        component: () => import("@/views/contact/ContactSupport.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "docs/games/vr",
        name: "Games VR",
        component: () => import("@/views/docs/games/gamesVR.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "docs/requirements",
        name: "Requirements",
        component: () => import("@/views/docs/requirements/requirements.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "traning",
        name: "Traning",
        component: () => import("@/views/traning/Traning.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "assessment-report",
        name: "Assessment Report",
        component: () => import("@/views/manage/users/AssessmentReports.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "location/admin",
        name: "Location Admin",
        component: () =>
          import("@/views/manage/locations/CreateListLocations.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "B2B-user/dashboard",
        name: "B2B dashboard",
        component: () =>
          import("@/components/general/DashboardHeader.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "financial-report",
        name: "Financial Report",
        component: () => import("@/views/stripe/userOverview.vue"),
        beforeEnter: () => {
          if (!isLogged()) return "/";
        },
      },
      {
        path: "/free-trial-end",
        name: "Free trial end",
        component: () => import("@/views/freeTrial/FreeTrialEnd.vue"),
        beforeEnter: (to, from, next) => {
          if (!isLogged()) {
            next("/");
          } else if (!hasFreeTrialExpired()) {
            next("/");
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    path: "/register-players",
    name: "Register players",
    component: () => import("@/views/players/RegisterPlayers.vue"),
  },
  {
    path: "/verify/:uid/:token",
    name: "Activate Email",
    component: () => import("@/views/auth/confirmEmail.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/Login.vue"),
    beforeEnter: (to, from, next) => {
      if (isLogged()) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: () => import("@/views/auth/ForgotPassword.vue"),
    beforeEnter: () => {
      if (isLogged()) return "/";
    },
  },
  {
    path: "/totplogin",
    name: "TOTP Login",
    component: () => import("@/views/auth/TOTPLogin.vue"),
    beforeEnter: (to, from) => canAccess(to, from),
  },
  {
    path: "/location-register",
    name: "Register location delegate",
    component: () =>
      import("@/views/manage/users/locationUsers/locationDelegate.vue"),
  },
  {
    path: "/recovery",
    name: "Recovery",
    component: () => import("@/views/auth/recovery.vue"),
    beforeEnter: (to, from) => canAccess(to, from),
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("@/views/auth/SignUp.vue"),
    beforeEnter: () => {
      if (isLogged()) return "/";
    },
  },
  {
    path: "/totpregister",
    name: "TOTP Register",
    component: () => import("@/views/auth/TOTPRegister.vue"),
    beforeEnter: (to, from) => canAccess(to, from),
  },
  {
    path: "/checkout",
    name: "Check Out",
    component: () => import("@/views/stripe/checkOut.vue"),
  },
  {
    path: "/survey",
    name: "Survey",
    component: () => import("@/views/survey/Survey.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
